<template>
  <div>
    <!-- <div class="d-flex flex-wrap align-items-center mb-3">
      <label class="d-block font-weight-semibold">{{ $t('product.price_area') }}</label>
      <div class="ml-auto">
        <a-button type="primary" ghost @click="onOpenModal">
          <a-icon type="edit" />
          {{ $t('utils.change') }}
        </a-button>
      </div>
    </div> -->
    <a-table
      :columns="columns"
      :row-key="(record, index) => index"
      :data-source="priceList"
      :loading="isLoading"
      :pagination="true"
      :scroll="scroll"
    >
      <template slot="price_area_id" slot-scope="text">
        {{ getName(text, 'areas') }}
      </template>
      <template slot="customer_category_id" slot-scope="text">
        {{ getName(text, 'customers') }}
      </template>
      <template slot="value" slot-scope="text, record">
        <div v-if="$route.query.master_product || $route.name === 'product.channel.distributor' || edit">
          {{ getCurrencies(record.currency).value || 'Rp' }}
          <a-input-number
            style="margin: -5px 0; width: 70%"
            class="ml-1"
            :value="text"
            :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
            @change="e => handleChange(e, record.id)"
          />
        </div>
        <div v-else>
          {{ format(text, record.currency) }}
        </div>
      </template>
      <template slot="effective_date" slot-scope="text, record">
        <a-date-picker
          v-if="$route.query.master_product || $route.name === 'product.channel.distributor' || edit"
          :value="text"
          :format="dateFormat"
          class="w-100"
          @change="e => handleChangeDate(e, record.id)"
        />
        <div v-else>
          {{ text ? `${$moment(text).format('DD MMM YYYY')}` : '' }}
        </div>
      </template>
    </a-table>

    <!-- <EditPriceArea
      :visible="visible"
      :prices="priceList"
      :areas="areas"
      :customers="customers"
      @close="onCloseModal"
      @submit="onSubmitPrice"
    /> -->
  </div>
</template>

<script>
// import debounce from 'lodash/debounce'
import { getArea, getPriceArea, getCustomerCategory } from '@/api/channels/distributor'
import { useCurrency } from '@/composable/useCurrency'
// import { parseDate } from '@/utils/dateTime'
import { columnsPriceArea } from '@/data/columns'
import EditPriceArea from '../Modal/EditPriceArea.vue'
import { getUom } from '@/api/inventory'

const CURRENCIES = [
  {
    value: 'IDR',
    label: 'Rupiah',
    symbol: 'Rp',
  },
  {
    value: 'MYR',
    label: 'Ringgit Malaysia',
    symbol: 'RM',
  },
  {
    value: 'SGD',
    label: 'Singapore Dollar',
    symbol: 'S$',
  },
  {
    value: 'USD',
    label: 'Dollar',
    symbol: '$',
  },
  {
    value: 'EUR',
    label: 'Euro',
    symbol: '€',
  },
]

export default {
  components: {
    EditPriceArea,
  },
  props: {
    edit: {
      type: Boolean,
      required: false,
    },
    submit: {
      type: Boolean,
      required: false,
    },
    model: {
      type: Object,
      default: () => {},
    },
    catalog: {
      type: Object,
      default: () => {},
    },
    flag: {
      type: String,
      default: () => '',
    },
  },
  setup() {
    const { format } = useCurrency()
    return { format }
  },
  data() {
    // this.fetchArea = debounce(this.fetchArea, 500)
    return {
      catalogId: null,
      dateFormat: 'DD/MM/YYYY',
      isLoading: false,
      visible: false,
      columns: columnsPriceArea,
      priceList: [],
      tempPriceList: [],
      areas: [],
      customers: [],
      listUom: [],
      scroll: {
        y: 190,
      },
    }
  },
  watch: {
    edit(newValue) {
      if (!newValue) {
        this.priceList = this.tempPriceList
        const catalog_price = {
            catalog_id: this.catalogId,
            price: this.priceList,
          }
        this.$emit('setPriceArea', catalog_price)
      }
    },
    submit(newValue) {
      if (!newValue) {
        this.init()
      }
    },
    catalog(value) {
      console.log({value_catalog: value})
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      Promise.all([
        this.fetchArea(),
        this.fetchCustomer(),
        this.fetchProducUom(),
        this.fetchPriceArea(),
      ])
      .then((response) => {
        this.areas = response[0]
        this.customers = response[1]
        this.listUom = response[2]
        if (response[3].length > 0) {
          response[3].forEach(({ catalogs }) => {
              catalogs.forEach(({ id, price_list }) => {
                this.catalogId = this.catalog?.id || id
                if (this.flag === 'multi') {
                  if (this.catalog?.id === id) {
                    price_list.forEach(item => {
                      this.priceList.push({
                        id: item.id,
                        price_area_id: item.price_area_id,
                        customer_category_id: item.customer_category_id,
                        currency: item.currency,
                        value: item.selling_price,
                        uom: item.uom,
                        effective_date: item.effective_date,
                        min_qty: 1,
                        price_qty: 1,
                      })
                      this.tempPriceList = this.priceList
                    })
                    const catalog_price = {
                      catalog_id: this.catalogId,
                      price: this.priceList,
                    }
                    this.$emit('setPriceArea', catalog_price)
                  }
                } else {
                  price_list.forEach(item => {
                    this.priceList.push({
                      id: item.id,
                      price_area_id: item.price_area_id,
                      customer_category_id: item.customer_category_id,
                      currency: item.currency,
                      value: item.selling_price,
                      uom: item.uom,
                      effective_date: item.effective_date,
                      min_qty: 1,
                      price_qty: 1,
                    })
                    this.tempPriceList = this.priceList
                  })
                  const catalog_price = {
                    catalog_id: this.catalogId,
                    price: this.priceList,
                  }
                  this.$emit('setPriceArea', catalog_price)
                }
              })
            })
          } else {
            const array1 = this.areas
            const array2 = this.customers
            const array3 = this.listUom.length > 0 ? this.listUom : [{id_uom: '1', uom: 'PCS'}]
            const array4 = [];
            this.catalogId = this.model?.catalogs?.items?.[0]?.id || null

            for (let i = 0; i < array1.length; i++) {
              for (let j = 0; j < array2.length; j++) {
                for (let k = 0; k < array3.length; k++) {
                  const obj = {
                    id: array4.length + 1,
                    price_area_id: array1[i].id,
                    customer_category_id: array2[j].id,
                    currency: '',
                    value: null,
                    uom: array3[k].uom,
                    effective_date: null,
                    min_qty: 1,
                    price_qty: 1,
                  };
                  array4.push(obj);
                }
              }
            }
            this.priceList = array4
            this.tempPriceList = array4
            const catalog_price = {
              catalog_id: this.model?.catalogs?.items?.[0]?.id,
              price: this.priceList,
            }
            this.$emit('setPriceArea', catalog_price)
          }
        this.isLoading = false
      })
      .catch(e => {
        console.error('e: ', e)
        this.isLoading = false
      })
    },
    getCurrencies(value) {
      return CURRENCIES.filter(v => v.value === value)
    },
    handleChange(newVal, key) {
      const newData = [...this.priceList];
      const temp = newData.map(v => {
        const data = {
          ...v,
          value: v.id === key ? newVal : v.value,
        }
        return data
      })
      this.priceList = temp
      const catalog_price = {
        catalog_id: this.catalogId || this.catalog?.title || this.model?.catalogs?.items?.[0]?.id || this.model?.catalogs?.items?.[0]?.title,
        price: this.priceList,
      }
      this.$emit('setPriceArea', catalog_price)
    },
    handleChangeDate(newVal, key) {
      const newData = [...this.priceList];
      const temp = newData.map(v => {
        const data = {
          ...v,
          effective_date: v.id === key ? newVal : v.effective_date,
        }
        return data
      })
      this.priceList = temp
      const catalog_price = {
        catalog_id: this.catalogId || this.catalog?.title || this.model?.catalogs?.items?.[0]?.id || this.model?.catalogs?.items?.[0]?.title,
        price: this.priceList,
      }
      this.$emit('setPriceArea', catalog_price)
    },
    async fetchPriceArea() {
      this.isLoading = true
      this.priceList = []
      const { business_id } = this.$route.query
      const bodyValue = {
        id: this.$route.params.id,
        business_id,
        params: {
          product_id: this.$route.query.id,
          effective_date: this.$moment().format(),
          // price_area_id <-- if admin
          // customer_category_id <-- if admin
        },
      }
      try {
        const { data: { data: response }} =  await getPriceArea(bodyValue)
        if (response) return response
      } catch(e) {
        console.log({e})
        return []
      }
    },
    async fetchArea(search = '') {
      this.areas = []
      const bodyValue = {
        id: this.$route.params.id,
        params: {
          business_id: this.$route.query.business_id,
          search,
          page: 1,
          limit: 100,
          sort: 'priceAreaName',
          order: 'createdAt',
        },
      }
      try {
        const { data: { data: response }} = await getArea(bodyValue);
        if (response) return response
      } catch(e) {
        console.log({e})
        return []
      }
    },
    async fetchProducUom() {
      try {
        const { data: { data: response }} = await getUom({
          id: this.$route.query.id,
          params: { business_id: this.$route.query.business_id },
        })
         const temp = response?.units?.map((v, i) => {
            const val = {
              id_uom: (i + 1).toString(),
              uom: v.unit,
            }
            return val
          }) || []
          return temp
      } catch(e) {
        console.log({e})
        return []
      }
    },
    async fetchCustomer() {
      this.customers = []
      try {
        const { data: { content: response }} = await getCustomerCategory({ business_id: this.$route.query.business_id })
        if (response) return response
      } catch(e) {
        console.log({e})
        return []
      }
    },
    // async onSubmitPrice(value) {
    //   // this.onCloseModal()
    //   this.isLoading = true
    //   const { business_id, workspace_id } = this.$route.query
    //   const price = value.map(item => ({
    //     ...item,
    //     value: +item.value,
    //   }))
    //   const bodyValue = {
    //     workspace_id,
    //     id: this.$route.query.id,
    //     data: {
    //       business_id,
    //       product_id: this.$route.query.id,
    //       catalog_price: [
    //         {
    //           catalog_id: this.catalogId,
    //           price,
    //         },
    //       ],
    //     },
    //   }
    //   await updatePriceArea(bodyValue)
    //     .then(async () => {
    //       const temp = await this.fetchPriceArea()
    //       if (temp.length > 0) {
    //         temp.forEach(({ catalogs }) => {
    //           catalogs.forEach(({ id, price_list }) => {
    //             this.catalogId = id
    //             price_list.forEach(item => {
    //               this.priceList.push({
    //                 id: item.id,
    //                 price_area_id: item.price_area_id,
    //                 customer_category_id: item.customer_category_id,
    //                 currency: item.currency,
    //                 value: item.selling_price,
    //                 uom: item.uom,
    //                 effective_date: item.effective_date,
    //                 // effective_date: parseDate(item.effective_date),
    //                 min_qty: 1,
    //                 price_qty: 1,
    //               })
    //               this.tempPriceList = this.priceList
    //             })
    //           })
    //         })
    //       } 
    //       this.$emit('handleEdit')
    //     })
    //     .catch(err => console.error(err))
    //     .then(() => this.isLoading = false)
    // },
    getName(id, type) {
      const result = type === 'areas' ? 'priceAreaName' : 'category_name'
      const name = this[type].find(item => item.id === id)
      return name?.[result] || '-'
    },
    onOpenModal() {
      this.visible = true
    },
    onCloseModal() {
      this.visible = false
    },
  },
}
</script>
