<script>
import { defineComponent } from 'vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { useSaveSectionOnScroll } from '../useSaveSectionOnScroll'
import WarehouseAndInventoryForm from './Form.vue'

export default defineComponent({
  components: {
    WarehouseAndInventoryForm,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    warehouses: {
      type: Array,
      required: true,
    },
    productinventory: {
      type: Object,
      required: true,
    },
    /** @type {ProductFormModel} */
    value: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { dirty, el, validationObserver} = useSaveSectionOnScroll(emit)

    return {
      el,
      dirty,
      validationObserver,
    }
  },
})
</script>

<template>
  <a-card ref="el">
    <a-skeleton v-if="loading" active />
    <div v-else>
      <h4 class="mb-5">
        Inventories
      </h4>

      <ValidationObserver ref="validationObserver" rules="required">
        <ValidationProvider
          v-slot="{ errors }"
          name="Inventories"
          rules="required"
          :messages="{
            required: 'Pilih salah satu gudang untuk produk ini'
          }"
        >
          <WarehouseAndInventoryForm
            :value="value.inventories"
            :warehouses="warehouses"
            :productinventory="productinventory"
            :catalog-items="value.catalogs.items"
            @input="(v) => $emit('input', { ...$props.value, inventories: v}) "
          />
          <div v-if="errors.length" class="text-danger mt-2">
            {{ errors[0] }}
          </div>
        </ValidationProvider>
      </ValidationObserver>
    </div>
  </a-card>
</template>
