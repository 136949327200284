<script>
import { defineComponent, ref, computed, watch } from 'vue'
import { ValidationObserver } from 'vee-validate'
import FormItem from '@/components/Input/FormItem.vue'
import TextEditor from '@/components/Input/TextEditor/index.vue'
import { useSaveSectionOnScroll } from './useSaveSectionOnScroll'
import Checkbox from '@/components/Input/Checkbox.vue'

export default defineComponent({
  components: {
    FormItem,
    TextEditor,
    ValidationObserver,
    Checkbox,
},
  props: {
    value: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    brands: {
      type: Array,
      required: true,
    },
    detail: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    rendering: {
      type: Boolean,
      required: true,
    },
    permission: {
      type: Array,
      required: true,
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const { el, dirty, validationObserver } = useSaveSectionOnScroll(emit)

    /**
     * @type {import('@vue/composition-api').Ref<import('@/types/product').ProductFormModel['detail']>}
     */
    const model = ref(props.value.detail)
    const filteredBrands = ref(props.brands)
    const edit = ref(false)
    const expanded = ref(false)
    watch(() => props.value, () => {
      model.value = props.value.detail
    }, { deep: true })

    const subRecurse = (subLists) => {
      let subParent = []
      subLists.forEach(subList => {
        let prop = 'child'
        let subChildren = []
        const { id, name, slug } = subList
        if (subList.children) {
          subChildren = subRecurse(subList.children)
          if (subChildren.length) prop = 'children'
        }
        subParent.push({
          value: id,
          label: name,
          slug,
          [prop]: subChildren,
        })
      })
      return subParent
    }

    const recurse = (lists) => {
      let parent = []
      lists.forEach(list => {
        let prop = 'child'
        let children = []
        const { id, name, slug } = list
        if (list.children) {
          children = subRecurse(list.children)
          if (children.length) prop = 'children'
        }
        parent.push({
          value: id,
          label: name,
          slug: slug,
          [prop]: children,
        })
      })
      return parent
    }

    const categoryOptions = computed(() => recurse(props.categories))

    const handleEdit = () => {
      edit.value = !edit.value
      emit('changeEditable', {detail: edit.value})
      if (edit.value === false) {
        const firstErrorElement = document.querySelector('#detail-product')
        model.value.title = props.detail.title
        model.value.long_description = props.detail.long_description
        window.scrollTo({
          behavior: 'smooth',
          top: firstErrorElement?.getBoundingClientRect().top + window.pageYOffset - 225,
        })
      }
    }
    
    watch(() => props.loading, () => {
      if (!props.loading) {
        edit.value = false
        emit('changeEditable', {detail: false})
        const firstErrorElement = document.querySelector('#detail-product')
        window.scrollTo({
          behavior: 'smooth',
          top: firstErrorElement?.getBoundingClientRect().top + window.pageYOffset - 225,
        })
      }
    }, { deep: true })

    watch(() => props.brands, () => {
      if (props.brands.length > 0) {
        filteredBrands.value = props.brands
      }
    }, { deep: true })

    const filterOption = (input, option) => {
      return (
        option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
      )
    }

    const getLabelBrand = (e) => {
      return filteredBrands.value.find(item => item.id === e)?.title
    }

    const truncated = (e) => {
      return e.length > 300 ? e.substring(0, 300) + '...' : e;
    }

    const changeExpanded = () => {
      expanded.value = !expanded.value
      if (expanded.value === false) {
        const firstErrorElement = document.querySelector('#detail-product')
        window.scrollTo({
          behavior: 'smooth',
          top: firstErrorElement?.getBoundingClientRect().top + window.pageYOffset - 225,
        })
      }
    }

    return {
      model,
      categoryOptions,
      validationObserver,
      el,
      dirty,
      edit,
      handleEdit,
      filteredBrands,
      filterOption,
      getLabelBrand,
      truncated,
      expanded,
      changeExpanded,
    }
  },
})
</script>

<template>
  <ValidationObserver ref="validationObserver" slim>
    <a-card ref="el">
      <a-skeleton v-if="rendering" active />
      <div v-else>
        <div class="mb-5 d-flex">
          <div>
            <h4 id="detail-product">
              {{ $t('product.product_details') }}
            </h4>
          </div>
          <div v-if="$route.query.edit && !edit && permission.includes('WRITE')" class="ml-auto">
            <a-button
              type="primary"
              size="large"
              :ghost="edit ? false : true"
              @click="handleEdit"
            >
              Edit
            </a-button>
          </div>
        </div>

        <div v-if="$route.query.edit && !edit">
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              {{ $t('product.product_name') }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="text-left mb-2">
              : {{ model.title }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              {{ $t('product.categories') }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="text-left mb-2">
              : {{ model.category_name }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              {{ $t('product.brand') }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="text-left mb-2">
              : {{ getLabelBrand(model.brand_id) || model.brand_id }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              {{ $t('product.item_condition') }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="text-left mb-2">
              : {{ model.condition === 'NEW' ? 'Baru' : 'Bekas' }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              {{ $t('product.importLabel') }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="text-left mb-2">
              : {{ model.imported ? 'Impor' : 'Lokal' }}
            </div>
          </a-col>
          <!-- <a-col class="mt-3 mb-2" :sm="{ span: 4 }">
          <div class="mb-2">
            <label class="d-block title-detail mb-3">{{ $t('product.item_condition') }}</label>
            <div class="condition font-weight-semibold">
              {{ model.condition === 'NEW' ? 'Baru' : 'Bekas' }}
            </div>
          </div>
        </a-col>
        <a-col class="mt-3 mb-2" :sm="{ span: 4 }">
          <div class="mb-2">
            <label class="d-block title-detail mb-3">{{ $t('product.importLabel') }}</label>
            <div class="imported font-weight-semibold">
              {{ model.imported ? 'Impor' : 'Lokal' }}
            </div>
          </div>
        </a-col> -->
          <a-col class="mt-2" :sm="{ span: 24 }">
            <div>
              <label class="d-block title-detail mb-3">{{ $t('product.description') }}</label>
              <div v-html="expanded ? model.long_description : truncated(model.long_description)" />
              <a v-if="model.long_description.length > 300" style="color: blue" @click="changeExpanded"> {{ expanded ? 'Show Less' : 'Show More' }} </a>
            </div>
          </a-col>
        </div>

        <section v-else>
          <div class="font-weight-semibold mb-4">
            {{ $t('product.product_information') }}
          </div>
          <div>
            <label class="mb-1">
              {{ $t('product.product_name') }} <sup class="text-danger">*</sup>
            </label>
            <!-- :label="`${$t('product.product_name')} *`" -->
            <FormItem
              :name="$t('product.product_name')"
              vid="title"
              rules="required"
              class="pcaFormItemLabel"
            >
              <!-- :placeholder="`${$t('product.product_name')} *`" -->
              <a-input
                v-model="model.title"
                class="h-48px"
                focus
              />
            </FormItem>

            <a-row v-if="$route.meta.title === 'Powerbiz - Add Product Redeem' || $route.meta.title === 'Powerbiz - Edit Product Redeem'" :gutter="24">
              <a-col :span="12">
                <label class="mb-1">
                  {{ $t('discount.start_date') }} <sup class="text-danger">*</sup>
                </label>
                <FormItem
                  :name="$t('discount.start_date')"
                  vid="start_date"
                  rules="required"
                >
                  <!-- :placeholder="`${$t('product.product_name')} *`" -->
                  <a-date-picker
                    v-model="model.start_period"
                    show-time
                    size="large"
                    class="w-100"
                    format="DD MMM YYYY HH:mm:ss"
                    :placeholder="$t('discount.start_date')"
                  />
                </FormItem>
              </a-col>
              <a-col :span="12">
                <label class="mb-1">
                  {{ $t('discount.end_date') }} <sup class="text-danger">*</sup>
                </label>
                <FormItem
                  :name="$t('discount.end_date')"
                  vid="title"
                  rules="required"
                  class="pcaFormItemLabel"
                >
                  <!-- :placeholder="`${$t('product.product_name')} *`" -->
                  <a-date-picker
                    v-model="model.end_period"
                    show-time
                    size="large"
                    class="w-100"
                    format="DD MMM YYYY HH:mm:ss"
                    :placeholder="$t('discount.end_date')"
                  />
                </FormItem>
              </a-col>
            </a-row>
          
            <label class="mb-1">
              {{ $t('product.categories') }} <sup class="text-danger">*</sup>
            </label>
            <FormItem
              :name="$t('product.categories')"
              vid="category"
              rules="required"
            >
              <!-- :placeholder="`${$t('product.categories')} *`" -->
              <a-input-group compact class="">
                <a-cascader
                  v-model="model.category_id"
                  class="text-left w-100 h-48px"
                  popup-class-name="kategori-options"
                  :options="categoryOptions"
                  placeholder=""
                />
              </a-input-group>
            </FormItem>
            <label class="mb-1">
              {{ $t('product.brand') }}
            </label>
            <FormItem
              :name="$t('product.brand')"
              vid="brand"
            >
              <a-select
                v-if="filteredBrands.length > 0"
                v-model="model.brand_id"
                size="large"
                class="select-antd-default"
                placeholder="Cari & Pilih Brand Produk"
                :filter-option="filterOption"
                allow-clear
                show-search
              >
                <a-select-option v-for="brd in filteredBrands" :key="brd.id">
                  {{ brd.title }}
                </a-select-option>
              </a-select>
              <!-- :placeholder="$t('product.brand')" -->
              <a-input
                v-else
                v-model="model.brand_id"
                class="h-48px"
              />
            </FormItem>

            <a-row class="mt-5">
              <a-col :sm="{ span: 8 }">
                <div class="mb-3">
                  <label class="d-block font-weight-semibold mb-3">{{ $t('product.item_condition') }}</label>
                  <FormItem>
                    <Checkbox
                      v-model="model.condition"
                      check-value="USED"
                      uncheck-value="NEW"
                      :label="$t('product.used_condition')"
                      class="ml-2"
                      :disabled="$route.query.edit || $route.query.master_product ? true : false"
                    />
                  </FormItem>
                </div>
              </a-col>
              <a-col :sm="{ span: 8 }">
                <div class="mb-3">
                  <label class="d-block font-weight-semibold mb-3">{{ $t('product.importLabel') }}</label>
                  <FormItem>
                    <Checkbox 
                      v-model="model.imported"
                      :label="$t('product.import')"
                      class="ml-2"
                      :disabled="$route.query.edit || $route.query.master_product ? true : false"
                    />
                  </FormItem>
                </div>
              </a-col>
            </a-row>

            <div v-if="$route.meta.title === 'Powerbiz - Add Product Redeem' || $route.meta.title === 'Powerbiz - Edit Product Redeem'">
              <label class="mb-1">
                {{ $t('product.short_description') }}
              </label>
              <FormItem
                :name="$t('product.short_description')"
                vid="brand"
              >
                <a-input
                  v-model="model.short_description"
                  class="h-48px"
                  placeholder="Maks. 70 Karakter"
                />
              </FormItem>
            </div>

            <div class="mt-4">
              <label class="d-block font-weight-semibold mb-3">{{ $t('product.description') }}</label>
              <FormItem>
                <TextEditor
                  v-model="model.long_description"
                  :placeholder="$t('product.write_something')"
                />
              </FormItem>
            </div>
          </div>
        </section>
        <div v-if="$route.query.edit && edit" class="mt-3 py-3 text-right footer">
          <a-button
            size="large"
            type="primary"
            ghost
            class="px-5 mr-3 ml-auto"
            :loading="loading"
            @click="handleEdit"
          >
            {{ $t('utils.cancel') }}
          </a-button>

          <a-button
            size="large"
            type="primary"
            class="px-5"
            :loading="loading"
            @click="$emit('saveProducts')"
          >
            {{ $t('utils.save') }}
          </a-button>
        </div>
      </div>
    </a-card>
  </ValidationObserver>
</template>

<style lang="scss">
.kategori-options ul.ant-cascader-menu {
  height: auto;
  max-height: 180px;
}
.title-detail {
  color: #999999;
  font-weight: 400;
  font-size: 13px;
}
.condition {
  background: #FADFDD;
  border: 2px solid #BB5A58;
  border-radius: 20px;
  width: 70%;
  text-align: center;
  padding: 10px;
}
.imported {
  background: #FFF5D9;
  border: 2px solid #D8B95D;
  border-radius: 20px;
  width: 70%;
  text-align: center;
  padding: 10px;
}
</style>
