import { i18n } from '@/i18n'

export const columnsStock = [
  {
    title: i18n.t('productAvailability.productName'),
    dataIndex: 'sku',
    key: 'sku',
    width: '21%',
    scopedSlots: {
      customRender: 'sku',
    },
  },
  {
    title: i18n.t('productAvailability.warehouseName'),
    dataIndex: 'warehouse_id',
    key: 'warehouse_id',
    width: '14%',
    scopedSlots: {
      customRender: 'warehouse_id',
    },
  },
  {
    dataIndex: 'availability',
    key: 'availability',
    width: '13%',
    slots: {
      title: 'title_availability',
    },
    scopedSlots: {
      customRender: 'availability',
    },
  },
  {
    dataIndex: 'in_order',
    key: 'in_order',
    width: '12%',
    slots: {
      title: 'title_in_order',
    },
    scopedSlots: {
      customRender: 'in_order',
    },
  },
  {
    dataIndex: 'on_hand',
    key: 'on_hand',
    width: '12%',
    slots: {
      title: 'title_on_hand',
    },
    scopedSlots: {
      customRender: 'on_hand',
    },
  },
  {
    title: i18n.t('product.base_unit'),
    dataIndex: 'unit',
    key: 'unit',
    width: '12%',
    scopedSlots: {
      customRender: 'unit',
    },
  },
  // For next development
  // {
  //   title: i18n.t('productAvailability.safetyStock'),
  //   dataIndex: 'allocated',
  //   key: 'allocated',
  //   width: 120,
  //   scopedSlots: {
  //     customRender: 'allocated',
  //   },
  // },
  {
    title: 'Status',
    dataIndex: 'allocatable',
    key: 'allocatable',
    width: '11%',
    scopedSlots: {
      customRender: 'allocatable',
    },
  },
  {
    title: '',
    dataIndex: 'catalog_id',
    key: 'catalog_id',
    width: '5%',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export const columnsWithdrawal = [
  {
    title: i18n.t('withdrawal.request_date'),
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: {
      customRender: 'created_at',
    },
  },
  {
    title: i18n.t('withdrawal.nominal'),
    dataIndex: 'amount',
    key: 'amount',
    scopedSlots: {
      customRender: 'amount',
    },
  },
  {
    title: i18n.t('withdrawal.status'),
    dataIndex: 'status',
    key: 'status',
    scopedSlots: {
      customRender: 'status',
    },
  },
]

export const columnsBill = [
  {
    title: i18n.t('finance.order_id'),
    dataIndex: 'order_number',
    key: 'order_number',
    width: 150,
  },
  {
    title: i18n.t('finance.total_bill'),
    dataIndex: 'amount',
    key: 'amount',
    width: 150,
    scopedSlots: {
      customRender: 'amount',
    },
  },
  {
    title: i18n.t('finance.expired_date'),
    dataIndex: 'expired_at',
    key: 'expired_at',
    width: 170,
    scopedSlots: {
      customRender: 'expired_at',
    },
  },
]

export const columnsPaymentChannel = [
  {
    title: i18n.t('finance.pay_number'),
    dataIndex: 'payment_number',
    key: 'payment_number',
    scopedSlots: {
      customRender: 'payment_number',
    },
  },
  {
    title: i18n.t('finance.total_bill'),
    dataIndex: 'total_amount',
    key: 'total_amount',
    scopedSlots: {
      customRender: 'total_amount',
    },
  },
  {
    title: i18n.t('finance.payment_deadline'),
    dataIndex: 'orders',
    key: 'orders',
    scopedSlots: {
      customRender: 'orders',
    },
  },
]

export const columnsCategory = [
  {
    title: i18n.t('product.category_name'),
    dataIndex: 'name',
    key: 'name',
    width: '70%',
    scopedSlots: {
      customRender: 'name',
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '20%',
    scopedSlots: {
      customRender: 'status',
    },
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    width: '10%',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export const columnsCreateDiscount = [
  {
    title: i18n.t('discount.program_buy'),
    dataIndex: 'min',
    key: 'min',
    scopedSlots: {
      customRender: 'min',
    },
  },
  {
    title: i18n.t('discount.discount_format'),
    dataIndex: 'max',
    key: 'max',
    scopedSlots: {
      customRender: 'max',
    },
  },
  {
    title: i18n.t('discount.promotion_value'),
    dataIndex: 'uom',
    key: 'uom',
    scopedSlots: {
      customRender: 'uom',
    },
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    width: 50,
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export const columnsMultipleDiscount = [
  {
    title: i18n.t('discount.program_buy'),
    dataIndex: 'min',
    key: 'min',
    scopedSlots: {
      customRender: 'min',
    },
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    width: 50,
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export const columnsCreateGR = [
  {
    title: '',
    dataIndex: 'action1',
    key: 'action1',
    width: 50,
    fixed: 'left',
    scopedSlots: {
      customRender: 'action1',
    },
  },
  {
    title: 'Product',
    dataIndex: 'catalog_id',
    key: 'catalog_id',
    fixed: 'left',
    width: 350,
    scopedSlots: {
      customRender: 'catalog_id',
    },
  },
  {
    title: 'Qty',
    dataIndex: 'quantity',
    key: 'quantity',
    // width: 130,
    scopedSlots: {
      customRender: 'quantity',
    },
  },
  {
    title: 'UoM',
    dataIndex: 'uom',
    key: 'uom',
    // width: 150,
    scopedSlots: {
      customRender: 'uom',
    },
  },
  // {
  //   title: 'No. Batch',
  //   dataIndex: 'batch_number',
  //   key: 'batch_number',
  //   width: 200,
  //   scopedSlots: {
  //     customRender: 'batch_number',
  //   },
  // },
  // {
  //   title: 'Exp. Date',
  //   dataIndex: 'expired_date',
  //   key: 'expired_date',
  //   width: 220,
  //   scopedSlots: {
  //     customRender: 'expired_date',
  //   },
  // },
  // {
  //   title: 'Tipe Stok',
  //   dataIndex: 'stock_type',
  //   key: 'stock_type',
  //   width: 200,
  //   scopedSlots: {
  //     customRender: 'stock_type',
  //   },
  // },
  {
    title: 'Base Qty',
    dataIndex: 'base_quantity',
    key: 'base_quantity',
    scopedSlots: {
      customRender: 'base_quantity',
    },
  },
  {
    title: 'Base UoM',
    dataIndex: 'total_price',
    key: 'base_uom',
    scopedSlots: {
      customRender: 'base_uom',
    },
  },
]

export const columnCreateAdjustment = [
  {
    title: '',
    dataIndex: 'action1',
    key: 'action1',
    width: 50,
    fixed: 'left',
    scopedSlots: {
      customRender: 'action1',
    },
  },
  {
    title: 'Product',
    dataIndex: 'catalog_id',
    key: 'catalog_id',
    fixed: 'left',
    width: 350,
    scopedSlots: {
      customRender: 'catalog_id',
    },
  },
  {
    title: 'Stok Tersedia',
    dataIndex: 'onhand',
    key: 'onhand',
    width: 150,
  },
  {
    title: 'Perubahan Stok',
    dataIndex: 'quantity',
    key: 'quantity',
    width: 150,
    scopedSlots: {
      customRender: 'quantity',
    },
  },
  {
    title: 'Stok Setelah Berubah',
    dataIndex: 'allocated',
    key: 'allocated',
    width: 150,
  },
]

export const columnsDistributor = [
  {
    title: i18n.t('distributor.name_distributor'),
    dataIndex: 'name',
    key: 'name',
    width: 225,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 250,
  },
  {
    title: i18n.t('distributor.mobile_phone'),
    dataIndex: 'phone_number',
    key: 'phone_number',
    width: 150,
  },
  {
    title: i18n.t('distributor.address'),
    dataIndex: 'address',
    key: 'address',
    width: 275,
    scopedSlots: {
      customRender: 'address',
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    scopedSlots: {
      customRender: 'status',
    },
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    width: 50,
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export const columnsDiscount = [
  {
    title: i18n.t('discount.program_name'),
    dataIndex: 'promotion_name',
    key: 'promotion_name',
    width: 270,
    scopedSlots: {
      customRender: 'promotion_name',
    },
  },
  {
    title: i18n.t('discount.discount_level'),
    dataIndex: 'tier',
    key: 'tier',
    scopedSlots: {
      customRender: 'tier',
    },
  },
  {
    title: i18n.t('discount.period'),
    dataIndex: 'start_date',
    key: 'start_date',
    width: 190,
    scopedSlots: {
      customRender: 'start_date',
    },
  },
  {
    title: i18n.t('discount.quota'),
    dataIndex: 'quota',
    key: 'quota',
    align: 'center',
  },
  {
    title: i18n.t('discount.status'),
    dataIndex: 'status',
    key: 'status',
    scopedSlots: {
      customRender: 'status',
    },
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    width: 50,
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export const columnsPriceArea = [
  {
    title: i18n.t('product.area'),
    dataIndex: 'price_area_id',
    key: 'price_area_id',
    scopedSlots: {
      customRender: 'price_area_id',
    },
  },
  {
    title: i18n.t('product.customer_type'),
    dataIndex: 'customer_category_id',
    key: 'customer_category_id',
    scopedSlots: {
      customRender: 'customer_category_id',
    },
  },
  {
    title: i18n.t('product.price'),
    dataIndex: 'value',
    key: 'value',
    // width: 160,
    scopedSlots: {
      customRender: 'value',
    },
  },
  {
    title: i18n.t('product.unit'),
    dataIndex: 'uom',
    key: 'uom',
    width: 90,
  },
  {
    title: i18n.t('product.effective_date'),
    dataIndex: 'effective_date',
    key: 'effective_date',
    // width: 150,
    scopedSlots: {
      customRender: 'effective_date',
    },
  },
]

export const columnsRedeem = [
  {
    title: i18n.t('order.productName'),
    dataIndex: 'title',
    key: 'name',
    width: 350,
    scopedSlots: {
      customRender: 'name',
    },
    class: 'text-center',
    ellipsis: true,
  },
  {
    title: i18n.t('redeem.category'),
    dataIndex: 'category_name',
    key: 'category_name',
    class: 'text-center',
    ellipsis: true,
  },
  {
    title: i18n.t('redeem.variant'),
    dataIndex: 'catalogs',
    key: 'catalogs',
    scopedSlots: {
      customRender: 'catalogs',
    },
    class: 'text-center',
    ellipsis: true,
  },
  {
    title: i18n.t('order.priceTitle'),
    dataIndex: 'range_price',
    key: 'range_price',
    scopedSlots: {
      customRender: 'range_price',
    },
    class: 'text-center',
    ellipsis: true,
  },
  {
    title: i18n.t('redeem.status'),
    dataIndex: 'status',
    key: 'status',
    scopedSlots: {
      customRender: 'status',
    },
    class: 'text-center',
    ellipsis: true,
  },
  {
    title: '',
    key: 'action',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export const columnsMaster = [
  {
    title: i18n.t('product.product_name'),
    dataIndex: 'detail',
    key: 'detail',
    width: 450,
  },
  {
    title: i18n.t('product.categories'),
    dataIndex: 'category',
    key: 'category',
    width: 150,
    scopedSlots: {
      customRender: 'category',
    },
  },
  {
    title: i18n.t('product.varian'),
    dataIndex: 'variant_count',
    key: 'variant_count',
    align: 'center',
    width: 100,
  },
  {
    title: i18n.t('product.range_price'),
    dataIndex: 'price',
    key: 'price',
    width: 220,
    scopedSlots: {
      customRender: 'price',
    },

  },
  {
    title: i18n.t('product.status'),
    dataIndex: 'status',
    key: 'status',
    width: 120,
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
  },
  {
    title: '',
    key: 'action',
    width: 50,
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export const columnsProduct = [
  {
    title: i18n.t('product.product_name'),
    dataIndex: 'detail',
    key: 'detail',
    width: 250,
    scopedSlots: {
      customRender: 'product_name',
    },
  },
  /** @TODO */
  {
    title: i18n.t('store_list_page.categories'),
    dataIndex: 'category_name',
    key: 'category_name',
    scopedSlots: {
      customRender: 'category_name',
    },
  },
  {
    title: i18n.t('store_list_page.variant_count'),
    dataIndex: 'variant_count',
    key: 'variant_count',
    scopedSlots: {
      customRender: 'variant_count',
    },
  },
  {
    title: i18n.t('store_list_page.range_price'),
    dataIndex: 'price',
    key: 'price',
    scopedSlots: {
      customRender: 'price',
    },
  },
  {
    title: i18n.t('store_list_page.product_status'),
    dataIndex: 'product_status',
    key: 'product_status',
    scopedSlots: {
      customRender: 'product_status',
    },
  },
  {
    title: i18n.t('store_list_page.mapping_status'),
    dataIndex: 'status',
    key: 'status',
    width: 150,
    scopedSlots: {
      customRender: 'status',
    },
  },
  {
    title: '',
    dataIndex: 'import_status',
    key: 'import_status',
    width: 50,
    align: 'center',
    scopedSlots: {
      customRender: 'import_status',
    },
  },
  {
    title: '',
    key: 'action',
    width: 50,
    scopedSlots: {
      customRender: 'action',
    },
  },
]
export const priceAreaColumn = [
  {
    title: i18n.t('product.priceArea'),
    dataIndex: 'priceAreaName',
    key: 'priceAreaName',
    width: 300,
    scopedSlots: {
      customRender: 'priceAreaName',
    },
  },
  {
    title: 'Coverage Area',
    dataIndex: 'coverage',
    key: 'coverage',
    scopedSlots: {
      customRender: 'coverage',
    },
  },
  {
    title: '',
    key: 'action',
    width: 50,
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export const columnsCustomer = [
  {
    title: i18n.t('customer.name'),
    dataIndex: 'customer_name', // name
    key: 'customer_name', // name
  },
  {
    title: i18n.t('customer.category'),
    dataIndex: 'category_name', // category
    key: 'category_name', // category
  },
  {
    title: i18n.t('customer.email'),
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: i18n.t('customer.mobile'),
    dataIndex: 'phone', // mobile
    key: 'phone', // mobile
  },
  {
    title: i18n.t('customer.distributor'),
    dataIndex: 'distributor',
    key: 'distributor',
    scopedSlots: {
      customRender: 'distributor', // status
    },
  },
  {
    title: i18n.t('customer.city'),
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: i18n.t('customer.status'),
    dataIndex: 'state', // status
    key: 'state', // status
    scopedSlots: {
      customRender: 'state', // status
    },
  },
  {
    title: '',
    key: 'status_active', // status
    scopedSlots: {
      customRender: 'status_active', // status
    },
  },
  {
    title: '',
    key: 'action',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export const customerCategoryColumn = [
  {
    title: i18n.t('customer.customer_category'),
    dataIndex: 'category_name',
    key: 'category_name',
    width: 300,
    scopedSlots: {
      customRender: 'category_name',
    },
  },
]

export const columnsProductMaster = [
  {
    title: i18n.t('product.product_name'),
    dataIndex: 'showimg_url',
    key: 'showimg_url',
    width: 350,
    scopedSlots: {
      customRender: 'showimg_url',
    },
  },
  {
    title: i18n.t('product.categories'),
    dataIndex: 'category',
    key: 'category',
    width: 150,
    scopedSlots: {
      customRender: 'category',
    },
  },
  {
    title: i18n.t('product.varian'),
    dataIndex: 'variant_count',
    key: 'variant_count',
    width: 90,
    align: 'center',
  },
  {
    title: i18n.t('product.range_price'),
    dataIndex: 'business_id',
    key: 'business_id',
    width: 225,
    scopedSlots: {
      customRender: 'business_id',
    },
  },
  {
    title: i18n.t('product.channel'),
    dataIndex: 'channel_count',
    key: 'channel_count',
    width: 100,
    align: 'center',
  },
  {
    title: i18n.t('product.status'),
    dataIndex: 'status',
    key: 'status',
    width: 90,
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
  },
  {
    title: '',
    key: 'action',
    align: 'right',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export const columsChannelProductsCatalog = [
  {
    title: 'Nama Katalog Channel', // i18n.t('store_list_page.store_products'),
    dataIndex: 'title',
    key: 'title',
    width: 190,
    scopedSlots: {
      customRender: 'title',
    },
  },
  {
    title: 'SKU Katalog Channel', // i18n.t('store_list_page.variant'),
    dataIndex: 'sku',
    key: 'sku',
    width: 180,
    scopedSlots: {
      customRender: 'sku',
    },
  },
  {
    title: 'Nama Katalog Master', // i18n.t('store_list_page.variant'),
    key: 'catalog_master',
    width: 180,
    scopedSlots: {
      customRender: 'catalog_master',
    },
  },
  {
    title: 'SKU Katalog Master', // i18n.t('store_list_page.variant'),
    key: 'sku_master',
    width: 180,
    scopedSlots: {
      customRender: 'sku_master',
    },
  },
  {
    title: 'Action',
    key: 'action',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export const columsProductsCatalogMaster = [
  {
    title: 'Nama Katalog Master', // i18n.t('store_list_page.store_products'),
    dataIndex: 'title',
    key: 'title',
    width: 190,
    scopedSlots: {
      customRender: 'title',
    },
  },
  {
    title: 'SKU Katalog Master', // i18n.t('store_list_page.variant'),
    dataIndex: 'sku',
    key: 'sku',
    width: 180,
    scopedSlots: {
      customRender: 'sku',
    },
  },
  {
    title: 'Nama Katalog Channel', // i18n.t('store_list_page.variant'),
    key: 'catalog_channel',
    width: 190,
    scopedSlots: {
      customRender: 'catalog_channel',
    },
  },
  {
    title: '',
    key: 'action',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export const columsAdjustment = [
  {
    title: i18n.t('adjustment.id'),
    dataIndex: 'number',
    key: 'number',
    // width: 190,
  },
  {
    title: i18n.t('fulfillment.warehouse'),
    dataIndex: 'warehouse_id',
    key: 'warehouse_id',
    // width: 180,
    scopedSlots: {
      customRender: 'warehouse_id',
    },
  },
  {
    title: i18n.t('adjustment.date'),
    dataIndex: 'date',
    key: 'date',
    // width: 190,
    scopedSlots: {
      customRender: 'date',
    },
  },
  {
    title: i18n.t('adjustment.type'),
    dataIndex: 'type',
    key: 'type',
    // width: 190,
    scopedSlots: {
      customRender: 'type',
    },
  },
  {
    title: '',
    key: 'action',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export const columnsGoodReceipt = [
  {
    title: 'No. GR',
    key: 'number',
    dataIndex: 'number',
    width: '22%',
    ellipsis: true,
    scopedSlots: { customRender: 'number' }, 
  },
  {
    title: i18n.t('productAvailability.vendorName'),
    key: 'supplier_title',
    dataIndex: 'supplier_title',
    ellipsis: true,
  },
  {
    title: i18n.t('productAvailability.grDate'),
    key: 'date',
    dataIndex: 'date',
    ellipsis: true,
    scopedSlots: { customRender: 'date' }, 
  },
  {
    title: i18n.t('productAvailability.reference'),
    key: 'reference_number',
    dataIndex: 'reference_number',
    ellipsis: true,
    scopedSlots: { customRender: 'reference_number' },
  },
  {
    title: '',
    key: 'x',
    dataIndex: '',
    scopedSlots: { customRender: 'action' },
  },
]

export const columnsCreateOrderManual = [
  {
    title: '',
    dataIndex: 'action1',
    key: 'action1',
    width: 50,
    fixed: 'left',
    scopedSlots: {
      customRender: 'action1',
    },
  },
  {
    title: 'SKU',
    dataIndex: 'catalog_id',
    key: 'catalog_id',
    fixed: 'left',
    width: 250,
    scopedSlots: {
      customRender: 'sku',
    },
  },
  {
    title: 'MSRP',
    dataIndex: 'msrp',
    key: 'msrp',
    width: 130,
    scopedSlots: {
      customRender: 'msrp',
    },
  },
  {
    title: 'Harga Jual',
    dataIndex: 'selling_price',
    key: 'selling_price',
    width: 200,
    scopedSlots: {
      customRender: 'selling_price',
    },
  },
  {
    title: 'Diskon Produk',
    dataIndex: 'product_discount',
    key: 'product_discount',
    width: 200,
    scopedSlots: {
      customRender: 'product_discount',
    },
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    key: 'qty',
    width: 200,
    scopedSlots: {
      customRender: 'qty',
    },
  },
  {
    title: 'Total',
    dataIndex: 'total_price',
    key: 'total_price',
    width: 200,
    scopedSlots: {
      customRender: 'total_price',
    },
  },
]

export const columnsRevisedDataGR = [
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    width: 250,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    width: 100,
    key: 'quantity',
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
    width: 50,
    key: 'uom',
  },
  {
    title: 'Revised Status',
    dataIndex: 'status',
    key: 'status',
    width: 150,
    scopedSlots: {
      customRender: 'status',
    },
  },
]

export const columnsRevisedDataStockAdjustment = [
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    width: 250,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    width: 100,
    key: 'quantity',
  },
  {
    title: 'Revised Status',
    dataIndex: 'status',
    key: 'status',
    width: 150,
    scopedSlots: {
      customRender: 'status',
    },
  },
]

export const columnsRevisedDataManualOrder = [
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    width: 200,
  },
  {
    title: 'Harga Jual',
    dataIndex: 'selling_price',
    key: 'selling_price',
  },
  {
    title: 'Diskon Produk',
    dataIndex: 'product_discount',
    key: 'product_discount',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    width: 100,
    key: 'quantity',
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
    width: 100,
    key: 'uom',
  },
  {
    title: 'Revised Status',
    dataIndex: 'status',
    key: 'status',
    width: 150,
    scopedSlots: {
      customRender: 'status',
    },
  },
]

export const columnDetailStockMovement = [
  {
    title: 'Tanggal',
    dataIndex: 'movement_date',
    key: 'movement_date',
    sorter: true,
    scopedSlots: {
      customRender: 'movement_date',
    },
  },
  {
    title: 'Transaksi',
    dataIndex: 'movement_type',
    key: 'movement_type',
    scopedSlots: {
      customRender: 'movement_type',
    },
  },
  {
    title: 'Jumlah Pergerakan',
    dataIndex: 'movement_quantity',
    key: 'movement_quantity',
    scopedSlots: {
      customRender: 'movement_quantity',
    },
  },
  {
    dataIndex: 'availability',
    key: 'availability',
    slots: {
      title: 'title_availability',
    },
    scopedSlots: {
      customRender: 'availability',
    },
  },
  {
    dataIndex: 'on_order',
    key: 'on_order',
    slots: {
      title: 'title_on_order',
    },
    scopedSlots: {
      customRender: 'on_order',
    },
  },
  {
    dataIndex: 'on_hand',
    key: 'on_hand',
    slots: {
      title: 'title_on_hand',
    },
    scopedSlots: {
      customRender: 'on_hand',
    },
  },
]

export const columnsHistoryWallet = [
  {
    title: i18n.t('finance.transaction_date'),
    dataIndex: 'created_at',
    key: 'created_at',
    width: 190,
    scopedSlots: {
      customRender: 'created_at',
    },
  },
  {
    title: i18n.t('utils.amount'),
    dataIndex: 'amount',
    key: 'amount',
    width: 190,
    scopedSlots: {
      customRender: 'amount',
    },
  },
  {
    title: i18n.t('utils.status'),
    dataIndex: 'status',
    key: 'status',
    width: 190,
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    scopedSlots: {
      customRender: 'note',
    },
    width: 190,
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    width: 50,
    scopedSlots: {
      customRender: 'action',
    },
  },
]