import { computed, ref } from 'vue'

const units = ref([
  'PCS',
  'DZN',
  'CTN',
])

export const useUnits = () => {
  const add = (value) => {
    units.value.includes(value) ? null : units.value.push(value)
  }
  const remove = (value) => { 
    const index = units.value.indexOf(value)
    if (index > -1) {
      units.value.splice(index, 1)
    }
  }
  const unitAsOptions = computed(() => units.value.map(u => ({ label: u, value: u })))
  const filteredUnitAsOptions = (selected) => !selected || (Array.isArray(selected) && !selected.length) ? unitAsOptions.value : unitAsOptions.value.filter(u => Array.isArray(selected) ? !selected.includes(u.value) : u.value !== selected)

  return { units, unitAsOptions, filteredUnitAsOptions, add, remove }
}
