var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a-collapse',{class:{ collapsed: !_vm.show },attrs:{"active-key":_vm.show,"expand-icon-position":"right","expand-icon":null}},[_c('div',{staticClass:"variant-table--summary"},[_vm._l((Object.values(_vm.catalog.option)),function(optionName,index){return _c('div',{key:index,staticClass:"option-name"},[_vm._v(" "+_vm._s(optionName)+" ")])}),_c('div',{staticClass:"ml-auto"},[(_vm.catalog.sku)?_c('span',[_c('a-tooltip',{attrs:{"title":_vm.catalog.sku}},[_vm._v(" "+_vm._s(_vm.truncated(_vm.catalog.sku))+" ")])],1):_c('span',{staticStyle:{"font-size":"80%","color":"#bbb"}},[_vm._v(" (Not set) ")])]),_c('div',{staticClass:"actions"},[_c('a-switch',{attrs:{"checked":_vm.catalog.status,"size":"small","disabled":_vm.$route.query.edit || _vm.$route.query.master_product ? true : false},on:{"change":(val) => {
          _vm.catalog.status = val
          _vm.$emit('input:status', val)
        }}})],1),_c('div',{staticClass:"caret"},[_c('button',{attrs:{"type":"button"},on:{"click":_vm.toggleShow}},[_c('a-icon',{attrs:{"type":"caret-right","rotate":!!_vm.show ? 90 : 0}})],1)])],2),_c('a-collapse-panel',{key:"1",staticClass:"variant-table--item",attrs:{"show-arrow":false,"force-render":""}},[_c('div',{staticClass:"variant-table--detail"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-4",staticStyle:{"width":"125px"}},[_c('ValidationProvider',{attrs:{"rules":{ required: _vm.catalog.status },"custom-messages":{
              required: 'Pilih minimal 1 gambar produk'
            },"immediate":"","name":`Gambar ${_vm.value.title}`,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('SelectPhoto',{class:{ 'has-feedback has-error': errors.length },attrs:{"images":_vm.images},model:{value:(_vm.catalog.images),callback:function ($$v) {_vm.$set(_vm.catalog, "images", $$v)},expression:"catalog.images"}}),(errors.length)?_c('div',{staticClass:"text-danger text-small mt-2 ant-form-explain"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"w-100"},[(_vm.$route.query.edit)?_c('div',{staticClass:"mt-4"},[_c('a-col',{attrs:{"span":6}},[_c('div',{staticClass:"title-detail d-flex justify-content-start mb-2"},[_vm._v(" Nama ")])]),_c('a-col',{attrs:{"span":18}},[_c('div',{staticClass:"text-left mb-2"},[_vm._v(" : "+_vm._s(_vm.catalog.title)+" ")])]),_c('a-col',{attrs:{"span":6}},[_c('div',{staticClass:"title-detail d-flex justify-content-start mb-2"},[_vm._v(" SKU ")])]),_c('a-col',{attrs:{"span":18}},[_c('div',{staticClass:"text-left mb-2"},[_vm._v(" : "+_vm._s(_vm.catalog.sku)+" ")])]),_c('a-col',{attrs:{"span":6}},[_c('div',{staticClass:"title-detail d-flex justify-content-start mb-2"},[_vm._v(" Status ")])]),_c('a-col',{attrs:{"span":18}},[_c('div',{staticClass:"text-left mb-2"},[_vm._v(" : "+_vm._s(_vm.catalog.status ? 'Aktif' : 'Tidak Aktif')+" ")])])],1):_c('a-row',{staticClass:"mb-3",attrs:{"type":"flex","gutter":16}},[_c('a-col',{attrs:{"xl":{ span: 24 }}},[_c('FormItem',{attrs:{"rules":{ required: _vm.catalog.status, no_space: true, excluded: _vm.skuExcludesRule },"custom-messages":{
                  excluded: 'SKU tidak boleh sama dengan varian lainnya'
                },"label":_vm.$t('SKU'),"name":`SKU [${_vm.value.title}]`}},[_c('a-input',{staticClass:"h-48px",attrs:{"disabled":_vm.$route.query.master_product ? true : false},model:{value:(_vm.catalog.sku),callback:function ($$v) {_vm.$set(_vm.catalog, "sku", $$v)},expression:"catalog.sku"}})],1)],1)],1),_c('Price',{attrs:{"edit":_vm.edit,"catalog":_vm.catalog,"submit":_vm.submit,"flag":_vm.flag,"vat-type":_vm.product.detail.vat_type,"business":_vm.business,"list-uom":_vm.listUom,"units":_vm.units,"unit":_vm.fixUnit ? _vm.catalog.price.uom : null,"label":`Harga Produk ${_vm.catalog.sku}`},on:{"changeuom":val => _vm.$emit('changeuom', val),"setPriceArea":(e) => _vm.$emit('setPriceArea', e)},model:{value:(_vm.catalog.price),callback:function ($$v) {_vm.$set(_vm.catalog, "price", $$v)},expression:"catalog.price"}})],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }