<script>
import { defineComponent, ref, computed }from 'vue'
import { ValidationProvider } from 'vee-validate'

export default defineComponent({
  components: {
    ValidationProvider,
  },
  props: {
    vid: {
      type: String,
      default: () => undefined,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    label: String,
    name: String,
    labelClass: [String, Object, Array],
  },
  setup() {
    const validationProvider = ref(null)
    const errors = computed(() => validationProvider.value?.errors || [])
    const error = computed(() => errors.value[0])

    return {
      validationProvider,
      errors,
      error,
    }
  },
})
</script>

<template>
  <div
    class="ant-input-group-wrapper"
    :class="{
      'has-feedback has-error': !!error,
    }"
  >
    <label v-if="label" class="" :class="labelClass">{{ label }}</label>
    <div class="ant-input-wrapper ant-input-group">
      <slot name="prepend" />
      <ValidationProvider
        ref="validationProvider"
        v-slot="slotProps"
        :vid="vid"
        :name="name || label"
        :rules="rules"
        tag="div"
      >
        <slot v-bind="slotProps" />
      </ValidationProvider>
      <slot name="append" />

      <span v-if="error" class="ant-form-item-children-icon">
        <a-icon type="close-circle" />
      </span>
    </div>
    <template v-if="error">
      <div class="ant-form-explain text-small">
        {{ error }}
      </div>
    </template>
  </div>
</template>
